.foo{
    display: flex;
    justify-content: space-between;
    width: 100vw;
    background-color: #e8ebee7c;
}
.left5{
    display: flex;
    justify-content: space-around;
    width: 85%;

}
.fle5{
    color: #507D2A;
    font-weight: bold;
}
.right5{
    width: 20%;
}
.one{
    padding-left: 5px;
    width: 20%;

}
.two{
    width: 20%;

}
.three{
    width: 20%;

}
.four{
    width: 40%;

}
.fle5 ul {
  list-style-type: none;
    padding-left: 0px;
}
.fle5 ul li{
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.twet{
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid white;
    margin-right: 10px;
}
.abp5 li {
    padding-left: 10px;
}
a{
    text-decoration: none;
    color: #507D2A;
}
@media screen and (max-width: 500px) {
    .foo{
        flex-wrap: wrap;
    }
    .left5{
        width: 100%;
        flex-wrap: wrap;
    }
    .right5{
        width: 100%;
    }
    .one{
        padding-left: 5px;
        width: 30%;
    
    }
    .two{
        width: 30%;
    
    }
    .three{
        width: 30%;
    
    }
    .four{
        width: 100%;
    
    }
}
