.home{
    width: 100vw;
    background:linear-gradient(0deg, rgba(127, 255, 212, 0.11), rgba(127, 255, 212, 0.81)), url('../../assets/background-signUpForm.jpg');
 background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
    height: 100vh;
    overflow-x: hidden;
  

}

.home::-webkit-scrollbar {
    display: none;
  }
  .top{
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 70px;
      align-items: center;
  }
  .log{
      width: 70px;
      height: 70px;
      object-fit: contain;
  }
.sign{
    background-color: rgba(60, 60, 206, 0.781);
    width: 110px;
    height: 38px;
    color: white;
    font-weight: 600;
    border-radius: 10px;
    margin-right: 10px;
    float: right;
    text-align: center;
    padding-top: 8px;
    cursor: pointer;
   
}
.fcontainer{
    display: flex;
    width: 100vw;

}
.left{
    margin-left: 20px;
    width: 72vw;


}

.right{
    width: 25vw;

}
.run{
    width: 80%;
    margin: auto;
}
.map{
    width: 100%;
    height: 90vh;
    margin-bottom: 30px;
}
.bts{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 30px;

}
.fd{
    display: flex;
    margin-left: 20px;
   align-items: center;
}
.ted{
    margin-left: 15px;
    font-weight: bold;
    font-size: 22px;

}
.bt{
    width: 180px;
    min-height: 40px;
    border-radius: 14px;
    padding-bottom: 2px;
    background-color: #507F9D;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s;
    color: #CCE2D8;
}
.bt:hover{
    background-color: white;
    font-weight: bold;

}

.slide{
    width: 80%;
    height: 50px;
    border-radius: 10px;
 
  margin: auto;

    margin-top: 20px;
 
    background-color: rgb(255, 255, 255);
}
.slider_title{
   font-weight: 600;
   text-align: center;
   
}
.piechart{
    margin: auto;
    margin-top: 10px;
    width: 100%;
 
}
.chart_title{
    margin: auto;
    width: 70%;
    text-align: center;

 font-weight: 550;
}
.chart_titl{
    margin: auto;
    width: 70%;
    text-align: center;

 font-weight: 550;
}
.instru{
    margin: auto;
    width: 100%;
    margin-top: 10px;
    text-align: center;

}
.check{
    margin-left: 10px;
    background-color: white;
    width: 30px;
    font-size: 28px;
    height: 10px;
}
.mod{
    margin-top: 10px;
    width: 100%;
    text-align: center;
   
    font-weight: bold;
}
.kd{
    font-size: 14px;
    color: #4682B4;
}
.dk{
    font-size: 14px;  
}
.dr{
   width: 100%;
   margin: auto; 
   margin-top: 10px;

  margin-bottom: 10px;
   text-align: center;

}


.dropbtn {
    width: 80%;
    height: 40px;
    border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
font-weight: 600;
    margin-top: 10px;
    cursor: pointer;
    background-color: white;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    width: 100%;
    position: relative;
    display: inline-block;
    margin: auto;
  }
  
 
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
 
  .dropdown-content div {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  

  .dropdown-content div:hover {background-color: #ddd;}
  

  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown:hover .dropbtn {background-color: #3e8e41;}
  .tx{
  display: none;
     
  }
 
.instruments{
    width: 80%;
    margin: auto;
    background-color: white;
    margin-top: 20px;
    padding-bottom: 20px;
    border: #507F9D 1px solid;
    border-radius: 10px 10px 10px 10px;
}
.dates{
    width: 80%;
    margin: auto;
    margin-top: 20px;
}
.che{
    display: flex;
    align-items: center;
}

.flex{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.fl2{
    width: 72vw;
    margin-left: 20px;
}
.fr2{
    width: 25vw;
}
@media screen and (max-width: 500px) {
    .fcontainer{
        flex-wrap: wrap;
    }
    .tx{
        display: block;
           
        }
        .tc{
            display: none;
        }
    .left{
        width: 100vw;
    
    
    }
    .right{
        width: 100vw;
    
    
    }
    .bts{
        width: 100%;
    }
    .bt{
        width: 20%;
    }

}